import constants from '../constants/site'
import Site from 'api/site'

export const fetchSite = () => async (dispatch, getState) => {
  dispatch({
    type: constants.FETCH_SITE_REQUEST
  })

  try {
    const response = await Site.fetch('')
    dispatch({
      type: constants.FETCH_SITE_SUCCESS,
      site: { id: response.id, ...response.attributes() }
    })
  } catch (response) {
    dispatch({
      type: constants.FETCH_SITE_FAILURE,
      errorMessage: response.statusText || response.status
    })
  }
}

export const setFilter = (filter) => {
  return {
    type: constants.SET_FILTER,
    filter
  }
}

export const updateSite = (key, value) => {
  return {
    type: constants.UPDATE_SITE,
    key,
    value
  }
}

export const saveSite = () => async (dispatch, getState) => {
  dispatch({
    type: constants.SAVE_SITE_REQUEST
  })

  const site = new Site(getState().currentSite.site)

  try {
    await site.save()
    dispatch({
      type: constants.SAVE_SITE_SUCCESS
    })
  } catch (response) {
    dispatch({
      type: constants.SAVE_SITE_FAILURE,
      errorMessage: response.message || response.statusText || response.status,
      errors: site.errors.extract()
    })
  }
}

export const launchSite = () => async (dispatch, getState) => {
  dispatch({
    type: constants.LAUNCH_SITE_REQUEST
  })

  const site = new Site(getState().currentSite.site)

  try {
    await site.launchSite()
    dispatch({
      type: constants.LAUNCH_SITE_SUCCESS
    })
  } catch (response) {
    dispatch({
      type: constants.LAUNCH_SITE_FAILURE,
      errorMessage: response.message || response.statusText || response.status,
      errors: site.errors.extract()
    })
  }
}

export const lockStatus = () => async (dispatch, getState) => {
  dispatch({
    type: constants.LOCK_STATUS_REQUEST
  })

  const sites = new Site()

  try {
    const response = await sites.lockStatus()
    dispatch({
      type: constants.LOCK_STATUS_SUCCESS,
      site: {
        ...response.attributes(),
        token: response.attributes().token,
        blocked: response.attributes().blocked

      }
    })
  } catch (response) {
    dispatch({
      type: constants.LOCK_STATUS_FAILURE,
      errorMessage: response.message || response.statusText || response.status
    })
  }
}

export const lockSite = () => async (dispatch, getState) => {
  dispatch({
    type: constants.LOCK_SITE_REQUEST
  })

  const sites = new Site()

  try {
    const response = await sites.lock()
    dispatch({
      type: constants.LOCK_SITE_SUCCESS,
      site: {
        ...response.attributes(),
        token: response.attributes().token,
        blocked: response.attributes().blocked
      }
    })
  } catch (response) {
    dispatch({
      type: constants.LOCK_SITE_FAILURE,
      errorMessage: response.statusText || response.status
    })
  }
}

export const unlockSite = () => async (dispatch, getState) => {
  dispatch({
    type: constants.UNLOCK_SITE_REQUEST
  })
  const sites = new Site()
  try {
    const response = await sites.unlock()
    dispatch({
      type: constants.UNLOCK_SITE_SUCCESS,
      site: {
        ...response.attributes(),
        token: response.attributes().token,
        blocked: response.attributes().blocked
      }
    })
  } catch (response) {
    dispatch({
      type: constants.UNLOCK_SITE_FAILURE,
      errorMessage: response.statusText || response.status
    })
  }
}
