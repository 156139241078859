import constants from 'constants/libraries';
import Intercom from 'api/intercom';

export const updateIntercom = () => async (dispatch) => {
  try {
    const intercom = await Intercom.query();

    dispatch({
      type: constants.FETCH_INTERCOM_DETAILS,
      intercom: { ...intercom.attributes() },
    });
  } catch (error) {
    console.log('Error in fetching intercom');
  }
};
