import { HttpAdapterConstructor } from '@itsfadnis/jsonapi-client/dist/http-adapter';
import Model from 'api/model';
import isProduction from './is-production';

type Args = {
  host: string;
  token: string;
  req?: any;
  isAnonymous?: boolean;
};

const getHeaders = (token, cookie) => {
  const headers: any = {};

  if (token) {
    headers['x-api-token'] = `Bearer ${token}`;
  }

  if (cookie) {
    headers.cookie = cookie;
  }

  return headers;
};

export const setup = ({ host, token, req }: Args) => {
  const cookie = req?.headers?.cookie;

  if (!cookie && !token) {
    return;
  }

  const newHost =
    isProduction || !process.env.NEXT_PUBLIC_DEV_API_ENDPOINT
      ? host
      : process.env.NEXT_PUBLIC_DEV_API_ENDPOINT;

  const headers = getHeaders(token, cookie);

  Model.configureAdapter({
    fetch,
    namespace: '/api/v2',
    host: newHost,
    headers,
  } as HttpAdapterConstructor);
};

export const initialiseAdapter = (ctx: any) => {
  if (typeof window === 'object') return;

  const host =
    process.env.NODE_ENV === 'production' ||
    !process.env.NEXT_PUBLIC_DEV_API_ENDPOINT
      ? `https://${ctx.req?.headers?.host}`
      : process.env.NEXT_PUBLIC_DEV_API_ENDPOINT;

  setup({
    host,
    token: ctx?.token || ctx.reduxStore.getState().currentUser.adapter?.token,
    req: ctx?.req,
  });
};
